<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch>
            </template>
        <template slot="menuRight">
            <el-input v-model="nameOrCod" :placeholder="$t('VARIETY ATTRIBUTE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <!-- <template slot="menu">
            <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">{{$t('DISABLE')}}</el-button>
        </template> -->
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,add,edit,remove,audit} from "@/api/mtrDic";
import {getProps} from "@/api/config";
export default {
    data() {
        return {
            tableLoading: false,
            selectionList: [],
            nameOrCod: "",
            status: "0",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: '',
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                delBtn: true,
                menuWidth: 200,
                dialogWidth: 500,
                selection: true,
                tip: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('NAME'),
                    row: true,
                    span: 24,
                    prop: "productPropertyValueName",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('CODE'),
                    row: true,
                    span: 24,
                    prop: "productPropertyValueCode",
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER THE CODE'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('DATA STATUS'),
                    addDisplay: false,
                    editDisplay: false,
                    prop: "isBase",
                    hide: true,
                    span: 24,
                    type: "select",
                    dicData: [{
                        label: this.$t('True'),
                        value: 1
                    }, {
                        label: this.$t('False'),
                        value: 0
                    }],
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER BASE UOM'),
                        trigger: "blur"
                    }
                }, {
                    label: this.$t('VARIETY ATTRIBUTE'),
                    span: 24,
                    dicUrl: this.businessUrl + "dict/selectDictByCategoryId?categoryId=95f2379e8d6d4e40816f46f222c53d85",
                    dicData: [],
                    type: "select",
                    prop: "code",
                    props: {
                        label: "name",
                        value: "code"
                    },
                    rules: {
                        required: true,
                        message: this.$t('PLEASE ENTER VARIETY ATTRIBUTE'),
                        trigger: "blur"
                    }
                }]
            }
        }
    },
    created() {
        getProps().then(res => {
            this.tableOption.column[3].dicData = res.data.data;
        });
      list().then(res => {
          this.tableData = res.data.data.items;
          this.page.total = res.data.data.total;

      })
    },
    methods: {
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        returnAudit() {
          audit(this.selectionList[0],0).then(() => {
              this.$message({
                  type: "success",
                  message: this.$t('DE APPROVAL SUCCEEDED')
              });
              this.list();
          })  
        },
        audit() {
          console.log("审核选择项",this.selectionList[0].id);
          audit(this.selectionList[0],1).then(() => {
              this.$message({
                  type: "success",
                  message: this.$t('AUDIT SUCCEEDED')
              });
              this.list();
          });
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then((res) => {
                     if (res.data.code == "0000") {
                        this.$message({
                        type: "success",
                        message: this.$t('DeleteSuccess'),
                        });
                     }
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row).then(() => {
                this.list();
                done();
            })
        },
        handleSave(row,done) {
            //获取品种属性label
            row.name = this.tableOption.column[3].dicData.find(v => {
               return v.code == row.code;
            }).name;
            add(row).then(() => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            this.selectionList = list;
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          list(this.page.pageCurrent,this.page.pageSize,this.nameOrCod,this.status).then(res => {
              this.tableData = res.data.data.items;
              this.page.total = res.data.data.total;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
    ::v-deep .el-dialog {
        width: 30%;
    }
</style>