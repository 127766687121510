import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,nameOrCode) => {
    return request({
        url : businessUrl + "productPropertyItem/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode
        }
    })
}
export const remove = (id) => {
    return request({
        url: businessUrl + "productPropertyItem/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const add = (params) => {
    return request({
        url: businessUrl + "productPropertyItem/insert",
        method: "post",
        data: {
            ...params
        }
    })
}
export const edit = (params) => {
    return request({
        url: businessUrl + "productPropertyItem/update",
        method: "put",
        data: {
            ...params
        }
    })
}
export const audit = (id,auditStatus) => {
    return request({
        url: businessUrl + "unit/audit",
        method: "post",
        param: {
            id: id,
            auditStatus: auditStatus
        }
    })
}
export const returnAudit = (id,status) => {
    return request({
        url: businessUrl + "unit/audit",
        method: "put",
        data: {
            id,status
        }
    })
}